import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { ContactLink } from '../components/ContactLink';
import SEO from '../components/core/Seo';
import Layout from '../layouts/Layout';
import BlankPage from '../templates/BlankPage';
import InfoPage from '../templates/InfoPage';
import { color, pixels, typography } from '../theme';

const Title = styled.h3`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f6};
  color: ${color.grey[70]};
  text-transform: uppercase;
  letter-spacing: ${typography.spacing.wide}em;
  margin-bottom: 2em;
`;

const Divider = styled.hr`
  background-color: ${color.grey[50]};
  height: ${pixels.one};
  border: none;
  margin-bottom: 3rem;
`;

const Section = styled.div`
  margin-bottom: 3rem;

  :last-child {
    margin-bottom: 0;
  }
  
  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f4};
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
    color: ${color.grey[90]};

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const SmallPrint = styled.div`
  margin-bottom: 3rem;
  color: ${color.grey[80]};
  font-size: ${typography.size.f7};

  :last-child {
    margin-bottom: 0;
  }

  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f6};
    margin-bottom: 0.5em;
    color: ${color.grey[100]};
  }

  ul {
    padding-inline-start: 1rem;

    li {
      margin-bottom: 1em;
    }
  }

  p {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const TermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Terms and Conditions" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <InfoPage span="T&C" title="Terms and Conditions">
        <BlankPage>
          <Title>Terms of service</Title>
          <Section>
            <h4>Cancellations</h4>
            <p>Appointments that are cancelled, rescheduled, or not attended without 24 hours notice will be charged at the full rate.</p>
          </Section>
          <Section>
            <h4>Pricing</h4>
            <p>Our prices are subject to change without notice.</p>
          </Section>
          <Section>
            <h4>Payment</h4>
            <p>Full payment for treatment must be made at time of appointment via cash or card</p>
          </Section>
          <Section>
            <h4>Refusal of Services</h4>
            <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
            <p>We take all reasonable care to ensure that details, descriptions and prices of servies appearing on our website are correct at the time of publishing. In the event that information has been mis-published, including prices and promotions, we reserve the right to refuse any affected offering.</p>
          </Section>
          <Divider />
          <SmallPrint>
            <h4>Copyright</h4>
            <ul>
              <li>All images remain the copyright of Knots. Permission must be sought prior to use.</li>
              <li>All rights, including copyright, in this website are owned by or licensed to Knots. Any use of the site or its contents, including copying or storing it (other than for your own personal, non-commercial use) is prohibited without our permission.</li>
            </ul>
          </SmallPrint>
          <SmallPrint>
            <h4>Force Majeure</h4>
            <ul>
              <li>We shall be under no liability if we, for any reason beyond our control, are unable to complete our appointment. In this case, we shall be at liberty to cancel or suspend the session.</li>
            </ul>
          </SmallPrint>
          <SmallPrint>
            <h4>Other</h4>
            <ul>
              <li>Should any provision within these Terms and Conditions be held by any competent authority to be invalid or unenforceable in whole or in part, the validity of all other provisions of these Terms and Conditions shall not be affected.</li>
              <li>The laws of England shall govern the contract between us, and any dispute between us will be resolved exclusively in the courts of England. Your statutory rights are not affected.</li>
            </ul>
          </SmallPrint>
          <SmallPrint>
            <p>By arranging an appointment/service with us, you agree to all the terms written here.</p>
          </SmallPrint>
        </BlankPage>
        <ContactLink />
      </InfoPage>
    </Layout>
  );
};

export default TermsAndConditions;
